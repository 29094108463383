import React from 'react'
import { SectionAbout } from 'features/section/components/SectionAbout';
import { SectionAction } from 'features/section/components/SectionAction';
import { SectionText } from 'features/section/components/SectionText';
import { SectionView } from 'features/section/components/SectionView';
import { Carousel} from 'features/gallery/components/Carousel';
import {Grid } from '@mui/material';
import { useValue } from 'context/ContextProvider';
import { SliderImages } from 'features/slider/components/Slider';
import { SectionHero}   from 'features/section/components/SectionHero';
import { SectionHeroText } from 'features/section/components/SectionHeroText';
export const SectionGenerator = ({sectionList}) => {  
 const {state: {currentRestaurant}} = useValue();


  return (
    <Grid container spacing={2}>
    {currentRestaurant &&sectionList && sectionList.map((item, index) => { return (
       
        item.restaurant_public_key === currentRestaurant.public_key && item.sections.map((section, k) =>{ return (
                
                section.enabled && (
                    <Grid key={index +'-section' + k} item xs={12}>
                        {section.type === "hero" &&
                            <SectionHero  section={section} index={k}/>
                        }
                        {section.type === "text" && 
                            <SectionText  section={section} index={k}/>
                        }
                        {section.type === "action" && 
                            <SectionAction  section={section} index={k}/>
                        }
                        {section.type === "image" && 
                            <SectionView  section={section} index={k}/>
                        }
                        {section.type === "about" && 
                            <SectionAbout  section={section} index={k}/>
                        }
                        {section.type === "gallery" && 
                            <Carousel  pages={section.images} url={section.url}/>
                        }
                        {section.type === "slider" && 
                            <SliderImages  images={section.images}/>
                        }
                        {section.type === "hero-text" &&
                            <SectionHeroText  section={section} index={k}/>
                        }
                    </Grid>
                    )
            
        )})
                                  
    )})}
    
    </Grid>
   )
}