export const isAdmin = (user) => {
    return ['admin'].includes(user?.role);
  };

export const isEmployee = (user) => {
    return ['employee'].includes(user?.role);
  };

  export const isManager = (user) => {
    return ['manager'].includes(user?.role);
  };

  export const isOwner = (user) => {
    return ['owner'].includes(user?.role);
  };

  export const isUser = (user) => {
    return ['user'].includes(user?.role);
  };


  