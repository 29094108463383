import React , {useEffect, Suspense,useState, lazy} from 'react';
import Login from 'features/auth/Login'
import posthog  from 'posthog-js';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useValue } from 'context/ContextProvider';

import { Footer } from 'pages/Footer';
import { CircularProgress } from '@mui/material';
import {Box,} from '@mui/material';
import { Default } from './Default';

import { NavbarDialog } from 'components/NavbarDialog';
// Lazy-loaded components
const Completed = lazy(() => import('./Completed').then(module => ({ default: module.Completed })));
const MyInvoice = lazy(() => import('./MyInvoice').then(module => ({ default: module.MyInvoice })));
const MyInvoices = lazy(() => import('./MyInvoices').then(module => ({ default: module.MyInvoices })));
const Profile = lazy(() => import('./Profile').then(module => ({ default: module.Profile })));
const Prenota = lazy(() => import('./Prenota').then(module => ({ default: module.Prenota })));
const Regala = lazy(() => import('./Regala').then(module => ({ default: module.Regala })));
const InvoicePaymentCompleted = lazy(() => import('./InvoicePaymentCompleted').then(module => ({ default: module.InvoicePaymentCompleted })));
const Contacts = lazy(() => import('./Contacts').then(module => ({ default: module.Contacts })));
const CookiePolicy = lazy(() => import('./CookiePolicy').then(module => ({ default: module.CookiePolicy })));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy').then(module => ({ default: module.PrivacyPolicy })));
const Terms = lazy(() => import('./Terms').then(module => ({ default: module.Terms })));
const Locale = lazy (() => import('./Locale').then(module => ({ default: module.Locale })));
const Cucina = lazy(() => import('./Cucina').then(module => ({ default: module.Cucina })));
const EmailVerification = lazy(() => import('./EmailVerification').then(module => ({ default: module.EmailVerification })));
const ResetPassword = lazy(() => import('./ResetPassword').then(module => ({ default: module.ResetPassword })));
const CookieBanner = lazy(() => import('features/cookies/compenents/CookieBanner').then(module => ({ default: module.CookieBanner })));
const AdvancedCookieBanner = lazy(() => import('features/cookies/compenents/AdvancedCookieBanner').then(module => ({ default: module.AdvancedCookieBanner })));
const ConfirmDialog = lazy(() => import('components/ConfirmDialog').then(module => ({ default: module.ConfirmDialog })));

export const Home = () => {

  const location = useLocation();
  // //background: isLight ?  `${theme.palette.backGround.light}` :`linear-gradient(to right bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main}`, width: '100%'}}>

    const {
        state: { currentUser, colors, sectionRefs},
        dispatch,
      } = useValue();
      const [isLcpLoaded, setIsLcpLoaded] = useState(false);
      useEffect(() => {
        // Function to set isLcpLoaded to true
        const handleLoad = () => {
            setIsLcpLoaded(true);
        };

        // Add event listener
        window.addEventListener('load', handleLoad);

        // Remove event listener on cleanup
        return () => window.removeEventListener('load', handleLoad);
    }, []);

    useEffect(() => {
        //list here all the locations that do not require scroll to top
        if (location.pathname !== '/noi'){
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }, [location]);  

return (<>

      <Login/>
      <ConfirmDialog/>
      <NavbarDialog/>
      <Routes>
          <Route path='*' element={<SuspenseWrapper component={Default} />}/>  
          <Route path='cookie-policy' element={<SuspenseWrapper component={CookiePolicy} />} />
          <Route path='contacts' element={<SuspenseWrapper component={Contacts} />} />
          <Route path='prenota' element={<SuspenseWrapper component={Prenota} />} />
          <Route path='regala' element={<SuspenseWrapper component={Regala} />} />
          <Route path='cucina' element={<SuspenseWrapper component={Cucina} />} />
          <Route path='locale' element={<SuspenseWrapper component={Locale} />} />
          <Route path='privacy-policy' element={<SuspenseWrapper component={PrivacyPolicy} />} />
          <Route path='note-legali' element={<SuspenseWrapper component={Terms} />} />
          <Route path='my-invoice/:invoiceId' element={<SuspenseWrapper component={MyInvoice} />} />
          <Route path='my-invoices' element={<SuspenseWrapper component={MyInvoices} />} />
          <Route path='profile' element={<SuspenseWrapper component={Profile} />} />
          <Route path="completed/:discountTempId/:success" element={<SuspenseWrapper component={Completed} />} />
          <Route path="invoice-completed/:invoiceId/:success" element={<SuspenseWrapper component={InvoicePaymentCompleted} />} />
          <Route path='verification/:token' element={<SuspenseWrapper component={EmailVerification} />} />
          <Route path='reset/:token' element={<SuspenseWrapper component={ResetPassword} />} />
      </Routes>
      <SuspenseWrapper component={Footer} />
        {isLcpLoaded && (
            <>
                {posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing() ? null : 
                    <>
                        <CookieBanner />
                        <AdvancedCookieBanner/>
                    </>
                }
            </>
        )}
      </>
)

}

function SuspenseWrapper({ component: Component }) {
  return (
    <Suspense fallback={
      <Box sx={{minHeight:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <CircularProgress aria-label="Loading..."/>
      </Box>
    }>
      <Component />
    </Suspense>
  );
}