import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ContextProvider from 'context/ContextProvider';
import {QueryClient ,QueryClientProvider} from '@tanstack/react-query'
import posthog from 'posthog-js'
posthog.init('phc_oOlqh4zWqFmn6X7jHFQg0YTB6lZUNfEgq9mK8ElhSyS', { api_host: 'https://eu.posthog.com' })
const queryClient = new QueryClient()
createRoot(document.getElementById('root')).render(
  
  <ContextProvider>
      <QueryClientProvider client={queryClient}>
       
              <div className="gradient-background">
                  <App />
              <div className="gradient-background-2"></div>
              </div>
              <div className="gradient-background-3">
              </div>

      </QueryClientProvider>
  </ContextProvider>
);

// If you want your app to work offline and load faster, you can chađinge
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();