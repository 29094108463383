import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import { useValue } from 'context/ContextProvider';

export const Loading = () => {
  const {
    state: { loading },
  } = useValue();
  return (
    <Backdrop open={loading} sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}>
      <CircularProgress aria-label="Loading..." sx={{ color: 'white' }} />
    </Backdrop>
  );
};

