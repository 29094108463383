import React, { useState } from 'react';

import {Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const BookButton = ({ mainProps, optionalProps = {}, url, text }) => {


 
  return (
      <Button
      {...optionalProps}
      {...mainProps}
      fullWidth
      variant={"contained"}
      sx={{ p: 1, borderRadius: 30,boxShadow:'none', maxWidth:150, minWidth:120 }}
      component={Link}
      size="large"
      
      to={url}
      color={"secondary"}
        >
      {text}
    </Button>
  );
};
