import React, { useRef, useState } from 'react';
import { useSprings, animated } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { Box } from '@mui/material';
import { Heading } from 'components/Heading';
import { useTheme } from '@mui/material/styles';
import useResponsiveImageSize from 'services/useResponsiveBackgroundImages';


function getSrcSet (image, url){
    return  `${url}/${image}/${image}-2560.webp 2560w, ${url}/${image}/${image}-1280.webp 1280w, ${url}/${image}/${image}-853.webp 853w, ${url}/${image}/${image}-640.webp 640w`
}

function getSrc (image, url){
    return  `${url}/${image}/${image}-2560.webp`
}

function getPlaceHolder (image, url){
    return  `${url}/${image}/${image}-blur.webp`
}



function Viewpager({pages, url}) {

  const index = useRef(0);
  const [currentPage, setCurrentPage] = useState(0);
  const width = window.innerWidth;
  const theme = useTheme();
  const imageSize = useResponsiveImageSize()
  const [props, api] = useSprings(pages.length, i => ({
    x: i * width,
    scale: 1,
    display: 'block',
  }));

  const bind = useDrag(({ active, movement: [mx], direction: [xDir], cancel }) => {
    if (active && Math.abs(mx) > width / 2) {
      const newIndex = (index.current + (xDir > 0 ? -1 : 1) + pages.length) % pages.length; // Infinite looping
      index.current = newIndex;
      setCurrentPage(newIndex);
      cancel();
    }
    api.start(i => {
      if (i < index.current - 1 || i > index.current + 1) return { display: 'none' };
      const x = (i - index.current) * width + (active ? mx : 0);
      const scale = active ? 1 - Math.abs(mx) / width / 2 : 1;
      return { x, scale, display: 'block' };
    });
  });

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
       
      {props.map(({ x, display, scale }, i) => (
        <animated.div
          {...bind()}
          key={i}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display,
            x: x.to(x => `${x}px`),
            touchAction: 'none', // Add this line
          }}
        >
         
         <animated.div style={{
              scale,
              backgroundImage: `url(${getSrc(pages[i]['image'],url)})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column', // Ensure the flex items are stacked vertically
              justifyContent: 'flex-end', // Align the flex container's children to the bottom
              alignItems: 'flex-start', // Align the flex container's children to the left
              color: 'white', // Adjust text color as needed
              fontSize: '24px', // Adjust font size as needed
              padding: '20px', // Add padding to ensure the text is not exactly at the edge
            }}>
              {pages[i]['text'] &&
                <Heading text={pages[i]['text']} color={theme.palette.grey.main}/>
              }
            </animated.div>
         
        </animated.div>
      ))}
      <div style={{ position: 'absolute', bottom: '10px', width: '100%', display: 'flex', justifyContent: 'center', gap: '10px' }}>
        {pages.map((_, i) => (
          <span
            key={i + 'other'}
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: i === currentPage ? 'white' : 'gray',
              cursor: 'pointer',
            }}
            onClick={() => {
              index.current = i;
              setCurrentPage(i);
              api.start(j => ({
                x: (j - i) * width,
                scale: 1,
                display: 'block',
              }));
            }}
          />
        ))}
      </div>
    </div>
  );
}

export const Carousel = ({pages, url}) => {

  const pagesInit = [
   { image: "tuna", text: "Fresh Tuna" },
   { image: "tuna", text: "Fresh Tuna" },
   { image: "tuna", text: "Fresh Tuna" },
  ];

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Viewpager pages={pages || pagesInit} url={url}/>
    </div>
  );
}
