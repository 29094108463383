import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WineBarIcon from '@mui/icons-material/WineBar';
import { useValue } from 'context/ContextProvider';

export const useGenerateSectionList = () => {
  

const {
  state: { currentRestaurant  },
  dispatch,
} = useValue();

const url = process.env.REACT_APP_PUBLIC_URL + `/images/${currentRestaurant.public_key}/`

function getSrcSet (image){
    return  `${url}/${image}/${image}-2560.webp 2560w, ${url}/${image}/${image}-1280.webp 1280w, ${url}/${image}/${image}-853.webp 853w, ${url}/${image}/${image}-640.webp 640w`
}

function getSrc (image){
    return  `${url}/${image}/${image}-2560.webp`

}

function getPlaceHolder (image){
    return  `${url}/${image}/${image}-blur.webp`
}


    function getHeroSrc(image) {
      return {
        xs: `${url}/${image}/${image}-640.webp`,
        sm: `${url}/${image}/${image}-853.webp`,
        md: `${url}/${image}/${image}-1280.webp`,
        lg: `${url}/${image}/${image}-2560.webp`,
      };
    }


const SectionList =[
    { 
        restaurant_public_key : 'FBD60CC0f298229fc455ccaA2D3a33',
        title: "Lofficina - Sirolo",
        description: "Ristorante Lofficina",
        menuUrl:"https://api.menutech.com/public/menus/LUYiWdzy/?base_lang=it&response=html",
        wineUrl:"https://api.menutech.com/public/menus/5neBrWcW/?base_lang=it&response=html",
        
        sections :[
                    {
                        type: "hero",
                        title: null,
                        enabled: true,
                        backdrop:true,
                        fullScreen: true,
                        body:null,
                        url:null,
                        text_button:'Scopri di più',
                        background_image_src: getHeroSrc('locale-window'),
                     
                    },
                    {     
                        type: "text",
                        text: <span>La semplicità della tradizione, <br /> L'emozione della novità</span>,
                        enabled: true,
                        size:'large'
                    },
                    {   
                        type: "image",
                        title: "La Cucina",
                        enabled: true,
                        body: <span>La nostra idea di cucina parte dall’amore per il nostro territorio, dai piatti della tradizione marchigiana, dal profumo del mare e dalla voglia di innovare.
                                    Partendo dalla scelta delle migliori materie prime, lavoriamo i prodotti con un occhio al futuro cercando, attraverso nuove tecniche di preparazione, di rendere davvero uniche le ricette della tradizione. Sperimentiamo, guardiamo oltre, ma senza esasperazioni.
                                </span>,
                        url:'/cucina',
                        text_button:'Scopri di più',
                        image: getSrc('gambero'),
                        imageSrcSet: getSrcSet('gambero'),
                        placeholder: getPlaceHolder('gambero')
                                
                    },
                    {
                        type: "action",
                        fullScreen:false,
                        title: "Leggi il menù",
                        enabled: true,
                        actions:[
                            {        
                            url:'/menu',
                            buttonText:'Menu',
                            icon:<MenuBookIcon/>
                            },
                            {        
                                url:'/vini',
                                buttonText:'Vini',
                                icon:<WineBarIcon/>
                            },
                        ]
                    },
                    {
                        type: "image",
                        title: "Il Locale",
                        enabled: true,
                        body: <span>Il sole che tramonta, il monte Conero davanti agli occhi e una terrazza a picco sul mare:
                        sono gli elementi che ogni giorno stimolano il nostro estro creativo e ci ricordano le nostre origini.
                        Un panorama tutto da godere con gli occhi, accompagnati dai sapori dei nostri piatti, in un contesto total white curato nei minimi dettagli. Un'atmosfera unica in cui trascorrere una serata all'insegna di cibo ed emozioni.</span>,
                        url:'/locale',
                        text_button:'Scopri di più',
                        image: getSrc('locale-window-top'),
                        imageSrcSet: getSrcSet('locale-window-top'),
                        placeholder: getPlaceHolder('locale-window-top')
                    },
                    {
                        type: "image",
                        title: "Sopra a San Michele",
                        enabled: true,
                        body:    <span>Avvolti dalla natura e dalla brezza del mare, il nostro terrazzo è un angolo di paradiso per gli amanti del gusto. Vieni a vivere un'esperienza culinaria indimenticabile, dove il canto del mare e il verde degli alberi faranno da cornice ai tuoi indimenticabili momenti di piacere.</span>,
                        url:'/locale',
                        text_button:'Scopri di più',
                        image: getSrc('terrace-front'),
                        imageSrcSet: getSrcSet('terrace-front'),
                        placeholder: getPlaceHolder('terrace-front')
                    },
                    {
                        type: "gallery",
                        title: "Sopra a San Michele",
                        enabled: false,
                        images:[{image:"tuna", text:"Fresh Tuna"},{image:"tuna", text:"Fresh Tuna"},{image:"tuna", text:"Fresh Tuna"}]
                    },
                    {
                        type: "image",
                        title: "Spedisci la tua valigia",
                        enabled: false,
                        body:  "possibili destinazioni",
                        image: getSrc('tuna'),
                        imageSrcSet: getSrcSet('tuna'),
                        placeholder: getPlaceHolder('tuna')
                    },
                    {   
                        type: "about",
                        title: "Noi",
                        subtitle1:<span>Davide <br />Breccia</span>,
                        subtitle2:<span>Andrea <br />Peccerillo</span>,
                        enabled: true,
                        body1: "Fin da piccolo ho sentito il desiderio di iscrivermi alla scuola alberghiera per entrare a far parte del mondo del food. Il Lido di Cesenatico di Vincenzo Camerucci ha rappresentato per me un inizio stimolante grazie alla vicinanza con uno dei pionieri della cucina contemporanea. Nel 2007 approdai nella cucina del ristorante Uliassi dove rimasi per 2 anni. Mauro fu un grande Maestro di cucina e di vita. La mia esperienza continuò anche fuori Italia, in Spagna a Madrid alla terrazza del “Casinò” di Paco Roncero (due stelle Michelin) e a San Sebastian al ristorante di Martin Berasategui (3 stelle Michelin). La Torre di Numana è l’ultimo ristorante dove ho lavorato. Insieme a Paolo Gioacchini abbiamo affinato la cucina e approfondito la conoscenza di nuove tecniche di cottura, preparazione e gestione di un ristorante. Ora ho intrapreso, insieme ad Andrea, questa nuova avventura in cui credo davvero molto. Il nostro obiettivo è trasmettere al cliente l’amore per il territorio e la passione che mettiamo nel lavoro attraverso le nostre creazioni, genuine ma con uno sguardo al futuro.",
                        body2: "La mia passione nasce da mio padre che ha sempre avuto ristoranti ed io, sin da piccolo, ho respirato sempre i profumi delle sue cucine, dove poi ho iniziato a compiere i primi passi. Ho vissuto esperienze lavorative molto diverse e, per questo, molto formative sotto tutti i punti di vista: dal caffè 900 di Porto San Giorgio fino all’esperienza di tre anni a Brighton, dove sono cresciuto professionalmente ricoprendo ruoli manageriali. Il mio percorso continua ora in Italia, con tante idee e voglia di innovare.Insieme a Davide, ho creato L’officina, un ristorante che non c’era a Sirolo. Vogliamo esaltare i sapori della nostra terra offrendo al cliente un’esperienza di gusto sofisticato denso di tradizione.",
                        url:'/',
                        text_button:'Scopri di più',
                        image: getSrc('tuna'),
                        imageSrcSet: getSrcSet('tuna'),
                        placeholder: getPlaceHolder('tuna')
                                
                    },
                    {
                        type: "action",
                        fullScreen:true,
                        title: "Regala una cena in stile Lofficina",
                        enabled: true,
                        actions:[
                            {        
                            url:'/regala',
                            buttonText:'Regala',
                            icon:<CardGiftcardIcon/>
                            },
                        ]
                    },
                    {
                        type: "slider",
                        enabled: true,
                        images : [
                            {
                              background_image_src: getHeroSrc("quadri-luce"),
                              height: 300,
                            },
                            {
                              background_image_src: getHeroSrc("aperitivo"),
                              height: 300,
                            },
                            {
                              background_image_src: getHeroSrc("gambero"),
                              height: 300,
                            },
                            {
                              background_image_src: getHeroSrc("locale-entrance-night"),
                              height: 300,
                            },
                            {
                              background_image_src: getHeroSrc("ricciola-vaniglia"),
                              height: 300,
                            },
                            {
                              background_image_src: getHeroSrc("locale-window"),
                              height: 300,
                            },
                           
                            
                           ]
                    },
                ]
},
{ 
  restaurant_public_key : '7e4f39DABBdFc9A05cf6b92B877bdb',
  title: "Losteria - Sirolo",
  description: "Ristorante Losteria",
  menuUrl:"https://api.menutech.com/public/menus/lUDuqZog/?base_lang=it&response=html",
  wineUrl:"https://api.menutech.com/public/menus/IDY6W6aZ/?base_lang=en&response=html",
  sections :[
              {
                  type: "hero",
                  title: null,
                  enabled: true,
                  backdrop:true,
                  fullScreen: true,
                  body:null,
                  url:null,
                  text_button:'Scopri di più',
                  background_image_src: getHeroSrc('losteria-entrance'),
               
              },
              {
                  type: "action",
                  fullScreen:false,
                  title: "Leggi il menù",
                  enabled: true,
                  actions:[
                      {        
                      url:'/menu',
                      buttonText:'Menu',
                      icon:<MenuBookIcon/>
                      },
                      {        
                          url:'/vini',
                          buttonText:'Vini',
                          icon:<WineBarIcon/>
                      },
                  ]
              },
              {
                  type: "image",
                  title: "Il Locale",
                  enabled: true,
                  body: <span>Situata nel cuore della tradizione culinaria, Losteria, rilevato da Andrea e Davide nel 2016, è un omaggio all'arte della cucina con un occhio sempre rivolto all'innovazione. Il nostro menù a base di pesce, la griglia a carbone e la pizza gourmet cotta nel forno a legna rendono Losteria la scelta ideale per ogni palato. Immergetevi in un'esperienza culinaria unica, dove il rispetto per la tradizione si fonde con la passione per il rinnovamento, facendo di Losteria il luogo perfetto per tutti.</span>,
                  url:'/locale',
                  text_button:'Scopri di più',
                  image: getSrc('tavolo-camino'),
                  imageSrcSet: getSrcSet('tavolo-camino'),
                  placeholder: getPlaceHolder('tavolo-camino')
              },
              {
                type: "image",
                title: "Una proposta tradizionale, ma diversa",
                enabled: true,
                body:    <span>Le nostre proposte culinarie, dal baccalà ai ravioli fatti in casa con umami di granchio, fino alla selezione di pesce crudo, sono un viaggio tra tradizione e modernità. Ogni piatto riflette la nostra passione per l'innovazione, mantenendo salde le radici nella cucina classica, per offrirvi un'esperienza gastronomica ricca di sapori autentici e sorprendenti.</span>,
                url:'/cucina',
                text_button:'Scopri di più',
                image: getSrc('kataifi'),
                imageSrcSet: getSrcSet('kataifi'),
                placeholder: getPlaceHolder('katifi')
            },
              {
                type: "image",
                title: "Forno a legna",
                enabled: true,
                body: <span>Ogni pizza è un capolavoro, preparato con dedizione e cotto alla perfezione, per regalare ai nostri ospiti un'esperienza culinaria indimenticabile. Venite a scoprire l'arte della pizza nel nostro ristorante, dove la passione per l'eccellenza si incontra con la magia del fuoco di legna.</span>,
                url:'/cucina',
                text_button:'Scopri di più',
                image: getSrc('forno-a-legna'),
                imageSrcSet: getSrcSet('forno-a-legna'),
                placeholder: getPlaceHolder('forno-a-legna')
              },
              {   
                  type: "about",
                  title: "Noi",
                  subtitle1:<span>Davide <br />Breccia</span>,
                  subtitle2:<span>Andrea <br />Peccerillo</span>,
                  enabled: true,
                  body1: "Fin da piccolo ho sentito il desiderio di iscrivermi alla scuola alberghiera per entrare a far parte del mondo del food. Il Lido di Cesenatico di Vincenzo Camerucci ha rappresentato per me un inizio stimolante grazie alla vicinanza con uno dei pionieri della cucina contemporanea. Nel 2007 approdai nella cucina del ristorante Uliassi dove rimasi per 2 anni. Mauro fu un grande Maestro di cucina e di vita. La mia esperienza continuò anche fuori Italia, in Spagna a Madrid alla terrazza del “Casinò” di Paco Roncero (due stelle Michelin) e a San Sebastian al ristorante di Martin Berasategui (3 stelle Michelin). La Torre di Numana è l’ultimo ristorante dove ho lavorato. Insieme a Paolo Gioacchini abbiamo affinato la cucina e approfondito la conoscenza di nuove tecniche di cottura, preparazione e gestione di un ristorante. Ora ho intrapreso, insieme ad Andrea, questa nuova avventura in cui credo davvero molto. Il nostro obiettivo è trasmettere al cliente l’amore per il territorio e la passione che mettiamo nel lavoro attraverso le nostre creazioni, genuine ma con uno sguardo al futuro.",
                  body2: "La mia passione nasce da mio padre che ha sempre avuto ristoranti ed io, sin da piccolo, ho respirato sempre i profumi delle sue cucine, dove poi ho iniziato a compiere i primi passi. Ho vissuto esperienze lavorative molto diverse e, per questo, molto formative sotto tutti i punti di vista: dal caffè 900 di Porto San Giorgio fino all’esperienza di tre anni a Brighton, dove sono cresciuto professionalmente ricoprendo ruoli manageriali. Il mio percorso continua ora in Italia, con tante idee e voglia di innovare.Insieme a Davide, ho creato L’officina, un ristorante che non c’era a Sirolo. Vogliamo esaltare i sapori della nostra terra offrendo al cliente un’esperienza di gusto sofisticato denso di tradizione.",
                  url:'/',
                  text_button:'Scopri di più',
                  image: getSrc('tuna'),
                  imageSrcSet: getSrcSet('tuna'),
                  placeholder: getPlaceHolder('tuna')
                          
              },
              {
                  type: "action",
                  fullScreen:true,
                  title: "Regala una cena in stile Lofficina",
                  enabled: true,
                  actions:[
                      {        
                      url:'/regala',
                      buttonText:'Regala',
                      icon:<CardGiftcardIcon/>
                      },
                  ]
              },
              {
                  type: "slider",
                  enabled: true,
                  images : [
                      {
                        background_image_src: getHeroSrc("enoteca"),
                        height: 300,
                      },
                      {
                        background_image_src: getHeroSrc("lamps"),
                        height: 300,
                      },
                      {
                        background_image_src: getHeroSrc("beccafico"),
                        height: 300,
                      },
                      {
                        background_image_src: getHeroSrc("baccalà"),
                        height: 300,
                      },
                      {
                        background_image_src: getHeroSrc("ravioli"),
                        height: 300,
                      },
                      {
                        background_image_src: getHeroSrc("in-alto-mare"),
                        height: 300,
                      },
                      {
                        background_image_src: getHeroSrc("kataifi"),
                        height: 300,
                      },
                      {
                        background_image_src: getHeroSrc("calamaretto"),
                        height: 300,
                      },
                     ]
              },
          ]
},
    

]
return SectionList
}