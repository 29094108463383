const userErrorInitialValue = {name: false, surname: false, email: false, phone_number: false, tax_id_code:false, address: {state:false, cap:false, city:false, cap:false, province:false, address:false, street_number:false} ,oldPassword: false, newPassword:false, passwordConfirm:false,
wages: {month:false, wage:false, payslip:false}}
const userProfileInitialValue = {name: '', surname: '', email: '', phoneNumber: '', taxIdCode : '', address: {state: 'it', cap: '', city:'', province: '', address:'', streetNumber: '', isResidential:false}, 
businessName:'',piva: '', iban: '', sdi:'', businessAddress: {state: 'it', cap: '', city:'', province: '', address:'', streetNumber: ''},
privacy: false, marketing:false, conditions:false, subscription:false, darkMode:false,balance:0,updateProfile: false, updateAddress:false,
oldPassword: '', newPassword:'', passwordConfirm:''}
const invoiceInitialState = {shippingList: [], surchargeList: [],costumer: null, amount: 0, invoiceNumber: '', expiryDate: '0'}
const discountInitialState =  {code:null,type:'card', title:null,description:null, costumer_name:null,phone_number:null,email:null,send_email:false,message:null,amount:null,min_amount:null,is_percentage:false,date_expired:null,privacy:false,marketing:false}
const discountErrorInitialState = {code:false,type:false, title:false,description:false,costumer_name:false,email:false,phone_number:false,card_type:false,amount:false,minAmount:false,isPercentage:false,daysValid:false}
const contactFormInitialValue = {name:'',email:'',message:''}
const restaurantDetailsInitialState = {business_id: null,name: '', description: '', email:'', image_url:'', alt_image_url:'', address: {state: '', cap: '', province: '', street_number: '', city: '', address:''}, phone_number : '', instagram_url : '', facebook_url :'', twitter_url : '', tripadvisor_url : '', 
google_my_business_url : '', linkedin_url : '', youtube_url :'', hostname:''};
const restaurantErrorInitialState ={name: false, description: false, email:false, phone_number:false,address: {state:false, cap:false, city:false, cap:false, province:false, address:false, street_number:false}}
const supplierDetailsInitialState = {business_id: null, name: null, vat_number: null, description: null, phone_number: null}
const supplierErrorInitialState = {name: false, phone_number: false}
const expenseDetailsInitialState = {business_id: null, supplier_id:null, restaurant_id:null,invoice_number: null, description: null, amount: null, amount_gross:null, amount_vat: null, date_created: null,expiry_date:null, is_extra:null}
const expenseErrorInitialState = {business_id:false, supplier_id:false,amount:false, }
const cashUpInitialState = {restaurant_id:null, cash_up_date:null, type:'final',breakfast_covers:null,lunch_covers:null,aperitif_covers:null,dinner_covers:null, bnt:null,cash:null,till:null,extra_expenses:null, notes:null,
staff:[]}
const cashUpErrorInitialState ={bnt:false,cash:false,till:false,staff:false}
const reducer = (state, action) => {
    switch (action.type) {
      
      case 'SET_SECTION_REF':
          return {...state,sectionRefs: {...state.sectionRefs,[action.payload.name]: action.payload.ref,},};
      case 'REMOVE_SECTION_REF':
          const updatedRefs = { ...state.sectionRefs };
          delete updatedRefs[action.payload];
          return {...state,sectionRefs: updatedRefs,};
      case 'OPEN_LOGIN':
        return { ...state, openLogin: true };
      case 'CLOSE_LOGIN':
        return { ...state, openLogin: false };
      case 'OPEN_COOKIE_BANNER':
          return { ...state, openCookieBanner: true };
      case 'CLOSE_COOKIE_BANNER':
          return { ...state, openCookieBanner: false };
      case 'OPEN_MENU':
      return { ...state, openMenu: { ...state.openMenu, ...action.payload } };
      case 'CLOSE_MENU':
          return { ...state, openMenu: false };
      case 'OPEN_ADVANCED_COOKIE_BANNER':
            return { ...state, openAdvancedCookieBanner: true };
      case 'CLOSE_ADVANCED_COOKIE_BANNER':
            return { ...state, openAdvancedCookieBanner: false };
      case 'OPEN_GALLERY':
        return { ...state, openGallery: true };
      case 'CLOSE_GALLERY':
        return { ...state, openGallery: false };
      case 'START_LOADING':
        return { ...state, loading: true };
      case 'END_LOADING':
        return { ...state, loading: false };
      case 'START_AUTH_LOADING':
        return { ...state, authLoading: true };
      case 'END_AUTH_LOADING':
          return { ...state, authLoading: false };
      case 'UPDATE_ALERT':
        return { ...state, alert: action.payload };
      //CAHSUP
      case 'CREATE_NEW_CASH_UP':
        return { ...state, cashUp: { ...state.cashUp, ...action.payload } };
      case 'RESET_CASH_UP':
        return { ...state, cashUp:  cashUpInitialState };
      case 'UPDATE_CASH_UP_ERROR':
        return { ...state, cashUpError: { ...state.cashUpError, ...action.payload } }; 
      case 'RESET_CASH_UP_ERROR':
        return { ...state, cashUpError: cashUpErrorInitialState };
      case 'UPDATE_CASH_UP_FILTER':
        return { ...state, cashUpFilter: { ...state.cashUpFilter, ...action.payload } };   
      //SHIFT
      case 'UPDATE_SHIFT_FILTER':
        return { ...state, shiftFilter: { ...state.shiftFilter, ...action.payload } };
      case 'UPDATE_SHIFT_ROW':
        return { ...state, selectedShiftRow: action.payload };   
      //WAGES
      case 'UPDATE_WAGES_FILTER':
        return { ...state, wagesFilter: { ...state.wagesFilter, ...action.payload } }; 
      case 'UPDATE_WAGE_ROW':
        return { ...state, selectedWageRow: action.payload };
      //RESTAURANT
      case 'UPDATE_RESTAURANT_DETAILS':
          return { ...state, restaurantDetails: { ...state.restaurantDetails, ...action.payload } };
      case 'UPDATE_RESTAURANT_ERROR':
        return { ...state, restaurantError: { ...state.restaurantError, ...action.payload } };
      case 'UPDATE_RESTAURANT_FILTER':
        return { ...state, restaurantFilter: { ...state.restaurantFilter, ...action.payload } };
      case 'RESET_RESTAURANT_DETAILS':
        return { ...state, restaurantDetails:  restaurantDetailsInitialState };
      case 'RESET_RESTAURANT_ERROR':
        return { ...state, restaurantError:  restaurantErrorInitialState };
        //EXPENSES
      case 'UPDATE_EXPENSES_FILTER':
        return { ...state, expensesFilter: { ...state.expensesFilter, ...action.payload } };
      case 'UPDATE_EXPENSE_DETAILS':
        return { ...state, expenseDetails: { ...state.expenseDetails, ...action.payload } };
      case 'RESET_EXPENSE_DETAILS':
        return { ...state, expenseDetails:  expenseDetailsInitialState }; 
      case 'UPDATE_EXPENSE_ERROR':
        return { ...state, expenseError: { ...state.expensesError, ...action.payload } };
      case 'RESET_EXPENSE_ERROR':
        return { ...state, expenseError:  expenseErrorInitialState };
       //SUPPLIER
      case 'UPDATE_SUPPLIERS_FILTER':
        return { ...state, suppliersFilter: { ...state.suppliersFilter, ...action.payload } };
      case 'UPDATE_SUPPLIER_DETAILS':
        return { ...state, supplierDetails: { ...state.supplierDetails, ...action.payload } };
      case 'RESET_SUPPLIER_DETAILS':
        return { ...state, supplierDetails:  supplierDetailsInitialState };
      case 'UPDATE_SUPPLIER_ERROR':
        return { ...state, supplierError: { ...state.supplierError, ...action.payload } };
      case 'RESET_SUPPLIER_ERROR':
        return { ...state, supplierError:  supplierErrorInitialState };
        //DISCOUNT
      case 'RESET_DISCOUNT':
          return { ...state, discount:  discountInitialState };
      case 'RESET_DISCOUNT_ERROR':
          return { ...state, discountError:  discountErrorInitialState };
      case 'UPDATE_DISCOUNT_ERROR':
          return { ...state, discountError: { ...state.discountError, ...action.payload } };
      case 'UPDATE_DISCOUNT_FILTER':
          return { ...state, discountFilter: { ...state.discountFilter, ...action.payload } };
      case 'UPDATE_SELECTED_DISCOUNT':
          return { ...state, selectedDiscount: action.payload };
      case 'UPDATE_DISCOUNT':
        return { ...state, discount: { ...state.discount, ...action.payload } };
      case 'UPDATE_PROFILE':
        return { ...state, profile: action.payload };
      //UPDATE CURRENT USER
      case 'UPDATE_CURRENT_USER':
        localStorage.setItem('currentUser', JSON.stringify(action.payload));
        return { ...state, currentUser: action.payload};
      //UPDATE CURRENT RESTAURANT
      case 'UPDATE_CURRENT_RESTAURANT':
    
        localStorage.setItem('currentRestaurant', JSON.stringify(action.payload));
        return { ...state, currentRestaurant: action.payload };
      case 'UPDATE_USER':
        return { ...state, user: action.payload };
      case 'UPDATE_CURRENT_YEAR':
        return { ...state, currentYear: action.payload };
      case 'TOGGLE_SHOW_HIDDEN':
        return { ...state, showHidden: action.payload };
      case 'TOGGLE_SHOW_VAT':
        return { ...state, showVat: action.payload };
      case 'RESET_CONTACT_FORM':
          return { ...state, contactForm:  contactFormInitialValue };
      case 'RESET_NEW_INVOICE':
            return { ...state, invoice:  invoiceInitialState };
     
      case 'CREATE_INVOICE':
          return { ...state, invoice: { ...state.invoice, ...action.payload } };
      case 'CONTACT_FORM':
          return { ...state, contactForm: { ...state.contactForm, ...action.payload } };
      
     //DASHBOARD
     case 'UPDATE_DASHBOARD_FILTER':
        return { ...state, dashboardFilter: { ...state.dashboardFilter, ...action.payload } };
     
      case 'UPDATE_INVOICE_FILTER':
        return { ...state, invoiceFilter: { ...state.invoiceFilter, ...action.payload } };
     
      case 'UPDATE_USER_FILTER':
          return { ...state, userFilter: { ...state.userFilter, ...action.payload } };
      case 'UPDATE_COOKIE_SETTINGS':
          return { ...state, cookieSettings: { ...state.cookieSettings, ...action.payload } };
      case 'UPDATE_USER_PROFILE':
        return { ...state, userProfile: { ...state.userProfile, ...action.payload } };
  
      case 'RESET_USER_ERROR':
          return { ...state, userError:  userErrorInitialValue };
      case 'RESET_USER_PROFILE':
          return { ...state, userProfile:  userProfileInitialValue };
      case 'UPDATE_USER_ERROR':
          return { ...state, userError: { ...state.userError, ...action.payload } };
      case 'OPEN_DIALOG':
          return { ...state, dialog: {open: true, close: false,id: action.payload.id, title: action.payload.title, message: action.payload.message, onSubmit: action.payload.onSubmit }};
      case 'CLOSE_DIALOG':
            return { ...state, dialog: {open: false, close: true, title: '', message: '', onSubmit: undefined }};
      case 'CHANGE_BODY_COLOR':
        return { ...state, colors: action.payload };
     
      default:
        console.log(action)
        throw new Error('No matched action!');
    }
  };
  
  export default reducer;
  