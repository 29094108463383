import React from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useValue } from 'context/ContextProvider';

export const MenuButton =({ mainProps, optionalProps = {},sectionName, url, text, scrollIntoView })=> {
  const navigate = useNavigate()
  const {
    state: { sectionRefs },
    dispatch,
  } = useValue();

  const handleNavigate = ()=> {
    dispatch({type :'OPEN_MENU', payload:{open:false,scroll:true,animate:false}})
    if (scrollIntoView ) {
        sectionName === 'noi' &&
        sectionRefs[sectionName]?.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
        navigate(url)
    }
  }

  return (
    <Button
        {...optionalProps}
        {...mainProps}
        onClick={handleNavigate}
        sx={{
            fontFamily: '"Playfair Display", serif',
            fontSize: 'clamp(3rem, 6vw, 5rem)', // Adjusts between 2rem and 5rem based on viewport width
            fontWeight: 'normal',
            textTransform: 'none',
            overflow: 'hidden', // Needed to contain the pseudo-element
            position: 'relative', // To position the pseudo-element
            transition: 'color 0.3s ease',
            ':hover': {
              color: 'secondary.main',
            },
            '&::before': { // Use ::before for the underline effect
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%) scaleX(0)', // Start from center and scaled down
              transformOrigin: 'center',
              width: '100%',
              height: '2px',
              backgroundColor: 'currentcolor',
              transition: 'transform 0.3s ease',
            },
            ':hover::before': {
              transform: 'translateX(-50%) scaleX(1)', // Scale up to full width on hover
            },
          }}
        >
          <Box component="span" sx={{ position: 'relative', zIndex: 1 }}>{text}</Box>
        </Button>
  );
}