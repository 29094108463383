import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useValue } from 'context/ContextProvider';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  FormControlLabel,
  Typography,
  Grid,
  Checkbox
} from '@mui/material';
export const UserConditions = ({type, isEdit}) => {
    const [subscriptionVisible, setSubscriptionVisible] = useState(false)
    const [marketingVisible, setMarketingVisible] = useState(false)
    const {
      state: { userProfile },
      dispatch,
    } = useValue();

    const handleChange = (e) => {
        dispatch({ type: 'UPDATE_USER_PROFILE', payload: {...userProfile, [e.target.name]: Boolean(e.target.checked) }})
      }

      useEffect(()=>{
        !userProfile.marketing && setMarketingVisible(true)
        !userProfile.subscription && setSubscriptionVisible(true)
      },[])
  return (<Grid container spacing={1}>
             {type === 'payment' &&
            <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', gap:1}}>
                <FormControlLabel
                control={
                    <Checkbox checked={userProfile.conditions} onChange={(e)=>handleChange(e)} disabled={!isEdit}
                    inputProps={{ 'aria-label': 'Termini e condizioni', name: 'conditions'}}  />
                }/>
                <Typography variant='subtitle1'> {'Accetto i '} 
                    <Link to="/note-legali" style={{ textDecoration: 'none' }} rel="noopener noreferrer" target="_blank" >
                        termini e condizioni
                    </Link>{' di acquisto'}
                </Typography> 
            </Grid>
            }
            {type === 'register' &&
            <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', gap:1}}>
                <FormControlLabel
                control={
                    <Checkbox checked={userProfile.privacy} onChange={(e)=>handleChange(e)} disabled={!isEdit}
                    inputProps={{ 'aria-label': 'Privacy', name: 'privacy'}}  />
                }/>
                <Typography variant='subtitle1'> {'Acconsento al trattamento dei dati personali ed ho preso visione dell '}
                    <Link to="/privacy-policy" style={{ textDecoration: 'none' }} rel="noopener noreferrer" target="_blank" >
                       informativa sulla privacy
                    </Link>
                </Typography>  
            </Grid>
            }
            {((type === 'payment'  && marketingVisible) || type !== 'payment') && 
                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', gap:1}}>
                    <FormControlLabel
                    control={
                        <Checkbox checked={userProfile.marketing} onChange={(e)=>handleChange(e)} disabled={!isEdit}
                        inputProps={{ 'aria-label': 'Marketing', name: 'marketing'}}  />
                    }/>
                    <Typography variant='subtitle1'> 
                        {'Voglio ricevere aggiornamenti sui servizi di spedizione e commerciali'}     
                    </Typography> 
                </Grid>
             }
             {((type === 'payment'  && subscriptionVisible) || type !== 'payment') && 
                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', gap:1}}>
                    <FormControlLabel
                    control={
                        <Checkbox checked={userProfile.subscription} onChange={(e)=>handleChange(e)} disabled={!isEdit}
                        inputProps={{ 'aria-label': 'Iscrizione alla newsletter', name: 'subscription'}}  />
                    }/>
                    <Typography variant='subtitle1'> 
                        {'Sì, voglio inscrivermi per ricevere informazioni promozionali'} 
                    </Typography> 
                </Grid>
            }
        </Grid>
  )
}
