
import React, { useEffect } from 'react';
import { useValue } from 'context/ContextProvider';
import jwtDecode from 'jwt-decode';
import { logout } from './AuthActions'
const useCheckToken = () => {
  const {
    state: {
      currentUser, authLoading
    },
    dispatch,
  } = useValue();
  useEffect(() => {
    dispatch({ type: 'START_AUTH_LOADING'})
    if (currentUser) {
      const decodedToken = jwtDecode(currentUser.accessToken);
      if (decodedToken.exp * 1000 < new Date().getTime()) {
      /*   storeRoom(
          location,
          details,
          images,
          updatedRoom,
          deletedImages,
          addedImages,
          currentUser.id
        ); */
        logout(dispatch);
       
      }
    }
    dispatch({ type: 'END_AUTH_LOADING'})

  }, []);
};

export default useCheckToken;