
/* {
    type: "action",
    title: "Regala una cena in stile Lofficina",
    enabled: true,
    body:  "possibili destinazioni",
    url:'/regala',
    text_button:'Regala',
} */

import React, {useRef, useState, useEffect} from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTheme } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';
import {
  Typography,
  Box,
  Grid,
} from '@mui/material';
import { ActionButton } from 'components/Buttons/ActionButton';

export const SectionAction = ({section, index}) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Set state based on whether the component is intersecting
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // null means it observes changes in the viewport
        rootMargin: '0px',
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  // Style object for fade-in effect
  const textFadeInStyle = useSpring({
    opacity: showText ? 1 : 0,
    transform: showText ? 'translateY(0)' : 'translateY(20px)',
    delay: 100,
   // Optional delay to ensure image animation is noticed
  });
    // Determine animation direction based on index
const fromDirection = index % 2 === 0 ? '-100%' : '100%';

    // Animation for the image
   
const imageAnimation = useSpring({
    from: { transform: `translate3d(${fromDirection}, 0, 0)`, opacity: 0 },
    to: {
      transform: isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)',
      opacity: isVisible ? 1 : 0,
    },
    config: { mass: 1, tension: 210, friction: 18 },
    onRest: () => setShowText(isVisible), // Callback when animation is done
  });

  return (
            <Box ref={ref} sx={{ minHeight:section.fullScreen ? '100vh' : '30vh', display:'flex', justifyContent:'center', alignItems:'center',p:2}}>
                <Grid container spacing={4}  >
                    <Grid item xs={12} sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                        <animated.div style={imageAnimation}>
                            <Typography variant='h2' sx={{color: theme.palette.grey.main  ,fontFamily: '"Playfair Display", serif',
                                    fontSize:'clamp(4rem, 6vw, 7rem)',
                                    fontWeight: 'normal',
                                    textTransform: 'none',}}>
                                    {section.title}
                            </Typography>
                        </animated.div>
                    </Grid>
                  
                    <Grid item xs={12} sx={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                        <animated.div style={textFadeInStyle}>
                            <Box sx={{display:'flex',alignItems:'center', justifyContent:'center',gap:1}}>
                                {section.actions && section.actions.map((action, index) => (
                                  <ActionButton text={action.buttonText} url={action.url} icon={action.icon} key={index+'-action-button'}/>
                                ))}
                            </Box>
                        </animated.div>
                    </Grid>
                   
                </Grid>
            </Box>
        );
}
