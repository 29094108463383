//MOVE LOGOS TO PUBLIC AND ADD PUBLIC_KEY
import React, { useState, useEffect } from 'react';
import { CircularProgress, Box } from '@mui/material';

export const LazyLogo = ({logo_url, maxWidth, maxHeight}) => {

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (logo_url === undefined) {
        return
    }
    const loadImage = async () => {
      // Dynamically import the image
      const image = await import(`assets/logo/${logo_url}`);
      setImageSrc(image.default);
    };

    loadImage();
  }, [logo_url]); // Empty dependency array ensures this runs once on mount

  if (!imageSrc || logo_url === undefined) {
    return <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: maxWidth, maxHeight: maxHeight}}>
                <CircularProgress />
            </Box>;
  }

  return <img src={imageSrc} alt="Logo" style={{ width: '100%' }} loading='lazy'/>;
}




/* import React, { useState, useEffect } from 'react';
import { CircularProgress, Box } from '@mui/material';

export const LazyLogo = ({ logoUrl, public_key, maxWidth, maxHeight }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  console.log(logoUrl)
  console.log(public_key)
  useEffect(() => {
    if (!logoUrl) {
      setIsLoading(false);
      return;
    }
    const image = new Image();
    image.onload = () => {
      setImageSrc(image.src);
      setIsLoading(false);
    };
    image.onerror = () => {
      console.error("Failed to load image at", image.src);
      setIsLoading(false);
      // Optionally set a fallback image
      // setImageSrc('/path/to/fallback/image.png');
    };
    // Adjust the path as needed
    image.src = `assets/logo/${logoUrl}`;

  }, [logoUrl, public_key]);

  if (isLoading || !imageSrc) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: maxWidth, maxHeight: maxHeight }}>
        <CircularProgress />
      </Box>
    );
  }

  return <img src={imageSrc} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%' }} loading="eager" />;
}; */