import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useTheme } from '@mui/material/styles';
import { Heading } from 'components/Heading';
import instagramLogo from "assets/logo/instagram_logo.svg"
import facebookLogo from "assets/logo/facebook_logo.svg"
import twitterLogo from "assets/logo/twitter_logo.svg"
import { useValue } from 'context/ContextProvider';
import {
  Box,
  Link,
  Typography,
  Grid,
} from '@mui/material';
export const Social = () => {
    const theme = useTheme();

    const {
        state: {
          currentRestaurant
        },
        dispatch,
      } = useValue();

  return (
    <Grid container spacing={2}>
        <Grid item xs={12} display={{xs:'none', sm:'block'}} >
                <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Heading text={'Social'} color={theme.palette.primary.main} size={'small'}/>
                </Box>
        </Grid>
        {currentRestaurant &&
        <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center',gap:1}}>
                <Box 
                        sx={{
                        maxWidth:'2em',
                        }}>
                        <Box sx={{filter:`invert(23%) sepia(0%) saturate(2480%) hue-rotate(316deg) brightness(95%) contrast(83%)`}} >
                                <a href={currentRestaurant.instagram_url} target='_blank' >
                                <img
                                src={instagramLogo}
                                alt="Visita il nostro profilo instagram"
                                width={'100%'}/>
                                </a>
                        </Box>
                </Box>
                <Box 
                        sx={{
                        maxWidth:'2em',
                        }}>
                        <Box sx={{filter:`invert(23%) sepia(0%) saturate(2480%) hue-rotate(316deg) brightness(95%) contrast(83%)`}}>
                                <a href={currentRestaurant.facebook_url} target='_blank'  >
                                <img
                                src={facebookLogo}
                                alt="Visita il nostro profilo facebook"
                                width={'100%'}/>
                                </a>
                        </Box>
                </Box>
                <Box 
                        sx={{
                        maxWidth:'2em',
                        }}>
                        <Box sx={{filter:`invert(23%) sepia(0%) saturate(2480%) hue-rotate(316deg) brightness(95%) contrast(83%)`}}>
                                <a href={currentRestaurant.twitter_url} target='_blank' >
                                <img
                                src={twitterLogo}
                                alt="Visita il nostro profilo twitter"
                                width={'100%'}/>
                                </a>
                        </Box>
                </Box>
        </Grid>
        }
    
      </Grid>
  )
}