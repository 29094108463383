import { Close, Send, Troubleshoot } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Grid,
  Fade
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useValue } from 'context/ContextProvider'
import PasswordField from './PasswordField';
import {authLogin, authRegister, sendResetEmail} from './authApi';
import {useMutation, useQueryClient} from '@tanstack/react-query'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { validPassword, validEmail} from 'services/Regex';
import { isManager, isAdmin, isEmployee } from 'services/getUserRole';
import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PasswordIcon from '@mui/icons-material/Password';
import { UserConditions } from 'features/profile/components/UserConditions';
import posthog from 'posthog-js';
const Login = ({mode}) => {
  const queryClient = useQueryClient();
  const form_data = new FormData()
  const theme = useTheme();
  const {
    state: { openLogin, userError, loading ,userProfile, currentRestaurant},
    dispatch,
  } = useValue();
  const [title, setTitle] = useState('Login');
  const [isRegister, setIsRegister] = useState(false);
  const [viewReset, setViewReset] = useState(false)
  const [reset, setReset] = useState(false)
  const [emailText, setEmailText] = useState('')
  const [passText, setPassText] = useState('')
  const [confPassText, setConfPassText] = useState('')
  const [nameText, setNameText] = useState('')
  const [surnameText, setSurnameText] = useState('')
  const nameRef = useRef();
  const surnameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();



  const {mutate: login,isLoading, error} = useMutation(authLogin, {
    onMutate: () =>{
      dispatch({ type: 'CLOSE_LOGIN' });
      dispatch({ type: 'START_LOADING' });
    },
    onSuccess: (data) => {
      if (data.accessToken) {
        dispatch({ type: 'UPDATE_CURRENT_USER', payload: data })
        posthog.identify(data.email)
         if (isAdmin(data) || isEmployee(data) || isManager(data)){
          navigate(location?.state?.previousUrl
            ? location.state.previousUrl
            : '/dashboard');
        }
      }},
      onSettled: () => {
      dispatch({ type: 'END_LOADING' });
    },
    onError: (data)=> {
      dispatch({ type: 'OPEN_LOGIN' });
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message:<div>{data.response.data.detail}</div>
          },}) 
      setViewReset(true)
      dispatch({ type: 'END_LOADING' }) },
  });

  const {mutate: register, isLoading: registerLoading,error: registerError} = useMutation(authRegister, {
    onMutate: () =>{
      dispatch({ type: 'START_LOADING' });

    },
    onSuccess: (data) => {
        if (data) {
          dispatch({
            type: 'UPDATE_ALERT',
            payload: {
              open: true,
              severity: 'success',
              message: 'Account creato correttamente, per favore verifica email',
            },
          });
        }
        handleClose()
        dispatch({ type: 'END_LOADING' });
      },
      onSettled: () => {
        // navigate("/dashboard/");    
    },
    onError: (data)=> {
      dispatch({ type: 'OPEN_LOGIN' });
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: <div>{data.response.data.detail}</div>
          },}) 
      dispatch({ type: 'END_LOADING' }) },
  });
  
  const {mutate: sendEmail, isLoading: emailIsLoading,error: emailError} = useMutation(sendResetEmail, {
    onMutate: () =>{
      dispatch({ type: 'START_LOADING' });
    },
    onSuccess: () => {
        dispatch({ type: 'END_LOADING' });
        handleClose()
      },
      onSettled: () => {
        dispatch({
          type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'success',
            message: 'Abbiamo inviato una mail di ripristino password, segui le istruzioni e reimposta la password',
          },
        });    
    },
    onError: (data)=> {
      dispatch({
        type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: <div>{data.response.data.detail}</div>
          },}) 
      dispatch({ type: 'END_LOADING' }) },
  });
  
  const handleClose = () => {
    dispatch({ type: 'CLOSE_LOGIN' });
    dispatch({ type: 'RESET_USER_ERROR' });
    setViewReset(false)
    setReset(false)
    clearErrors()

  };

  const clearErrors = () => {
    setEmailText('')
    setPassText('')
    setConfPassText('')
    setNameText('')
    setSurnameText('')
    dispatch({ type: 'RESET_USER_ERROR' });
  }
  
  const handleSubmit = (e) => {
    const restaurant_key = currentRestaurant.public_key
    e.preventDefault();
    if (handleValidation()){}
    const email = emailRef.current.value;
    const password = passwordRef.current?.value ;
      if (!isRegister && !reset && handleValidation()) {
          form_data.append("username",email)
          form_data.append("password", password)
          return login(form_data);
    }
    if (isRegister && !reset && handleValidation()) {
      const name = nameRef.current.value;
      const surname = surnameRef.current.value;
      const confirmPassword = confirmPasswordRef.current.value;
      const privacy = userProfile.privacy
      const marketing = userProfile.marketing
      const subscription = userProfile.subscription
      if (password !== confirmPassword)
        return dispatch({
          type: 'UPDATE_ALERT',
          payload: {
            open: true,
            severity: 'error',
            message: 'Le password non corrispondono',
          },
        });
      const hashed_password = password
      
      
      return register({ name, surname, email, hashed_password , privacy, marketing,subscription, restaurant_key})
    }
    if (!isRegister && reset && handleValidation()){
      sendEmail({email:email, restaurant_key:restaurant_key})
    }
  };

  useEffect(() => {
    isRegister ? setTitle('Registrati') : viewReset ? setTitle('Reimposta password') : setTitle('Login');
  }, [isRegister, viewReset]);

  useEffect(()=>{
    if ((isLoading || registerLoading || emailIsLoading) && !loading ){
      dispatch({ type: 'START_LOADING' })
   }
  },[isLoading, registerLoading, emailIsLoading])
  

  const handleToggle = (action) => {
    if (action === 'login'){
      setIsRegister(!isRegister)
      setReset(false)
    }
    if (action ==='reset'){
      setReset(true)
    }
    clearErrors() 
  }

const handleValidation = ()=>{
    let passErr =false
    let emailErr = false
    let nameErr = false
    let surnameErr = false
    let confirmErr = false
    if (emailRef?.current.value === ''){
      emailErr = true
      //set helper text
      setEmailText('Campo obbligatorio') 
    }else{
       //check email format
      if (!validEmail.test(emailRef?.current.value)){
        setEmailText('Formato email non corretto')
        emailErr = true;
      }else {
        setEmailText('')
        emailErr = false;
      }
    }
    if (!reset){
      if (passwordRef?.current.value === ''){
          passErr = true
          //set helper text 
          setPassText('Campo obbligatorio')
      }else{
          //check password format
          if (!validPassword.test(passwordRef?.current.value) && isRegister && !reset){
            passErr = true;
            setPassText('La password deve contenere almeno 6 caratteri, 1 numero, 1 lettera ed un carattere speciale')
          }else {
            passErr = false;
            setPassText('')
          }
      }
      if (isRegister && !reset) {
          //check confirm password
          if (confirmPasswordRef?.current.value === ''){
            confirmErr = true
            setConfPassText('Campo obbligatorio')
          } else{
            if (confirmPasswordRef?.current.value !== passwordRef.current.value){
              confirmErr = true
              setConfPassText('Le password non coincidono')
            } else {
              confirmErr = false
              setConfPassText('')
            }
          }
            //check name and surname
          if (nameRef?.current.value === ''){
            nameErr = true
            setNameText('Campo obbligatorio')
          } else {
            nameErr = false
            setNameText('')
          }
          if (surnameRef?.current.value === ''){
            surnameErr = true
            setSurnameText('Campo obbligatorio')
          } else {
            surnameErr = false
            setSurnameText('')
          }
        } 
        
    }
    dispatch({type: 'UPDATE_USER_ERROR' , payload: {...userError,  newPassword: passErr, emailAddress:emailErr, passwordConfirm:confirmErr, name:nameErr,surname:surnameErr}})

    if (passErr || emailErr || confirmErr || nameErr || surnameErr) {
      return false
    } else{
      return true
    }
}

  const actions = {reset : {text: 'Reimposta la password',icon:<PasswordIcon />}, register : {text: 'Registrati',icon:<ArrowForwardIosIcon />}, login:{text:'Login', icon:<LockIcon/>}}
  return (
    <Dialog open={openLogin} onClose={handleClose} disableScrollLock={true} hideBackdrop={mode === 'register'} PaperProps={{
      style: { borderRadius: 20 }
    }}>
      <form onSubmit={handleSubmit}>
      <DialogTitle>
        {title}
        <IconButton aria-label="close" sx={{position: 'absolute',top: 8,right: 8,color: (theme) => theme.palette.grey[500],}} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography variant='subtitle2'>
              {'Compila i seguenti campi'}
            </Typography>
          </DialogContentText>
          {isRegister && (
            <TextField
              InputProps={{ sx: { borderRadius:5} }}
              autoFocus
              margin="normal"
              variant="outlined"
              id="name"
              label="Nome"
              type="text"
              fullWidth
              inputRef={nameRef}
              onChange={clearErrors}
              error={userError.name}
              helperText={nameText}
              inputProps={{ minLength: 2 }}
              required
            />)}
             {isRegister && (<TextField
            InputProps={{ sx: { borderRadius:5} }}
            margin="normal"
            variant="outlined"
            id="surname"
            label="Cognome"
            onChange={clearErrors}
            error={userError.surname}
            helperText={surnameText}
            type="text"
            fullWidth
            inputRef={surnameRef}
            inputProps={{ minLength: 2 }}
            required
          />)}
          
          <TextField
            InputProps={{ sx: { borderRadius:5} }}
            autoFocus={!isRegister}
            margin="normal"
            variant="outlined"
            id="email"
            label="Email"
            type="email"
            onChange={clearErrors}
            helperText={emailText}
            fullWidth
            error={userError.emailAddress}
            inputRef={emailRef}
            required
          />
          {!reset &&
            <PasswordField 
            passwordRef={passwordRef}
            id="Password"
            name="newPassword"
            label="Password" 
            error={userError.newPassword}
            helperText={passText}
            onChange={clearErrors}/>
          }
          {isRegister &&  (
            <PasswordField
              passwordRef={confirmPasswordRef}
              id="confirmPassword"
              name="confirmPassword"
              label="Conferma Password"
              error={userError.passwordConfirm}
              helperText={confPassText}
              onChange={clearErrors}
            />
          )}
           {isRegister &&
            <UserConditions isEdit={true} type={'register'}/>
            }
        </DialogContent>
        <DialogActions>
            <Grid container spacing={1} sx={{pl:2,pr:2}}>
                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'space-around', gap:1}}>
                      <Button type="submit" variant="contained" size='large' sx={{p:2,borderRadius:5}} onClick={handleSubmit} disabled={isRegister && !userProfile.privacy}
                      fullWidth startIcon={!reset ? isRegister ? actions['register'].icon : actions['login'].icon : actions['reset'].icon}>
                      {!reset ? isRegister ? actions['register'].text : actions['login'].text : actions['reset'].text}
                      </Button>
                </Grid>
                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', gap:1}}>
                    <Typography variant='subtitle1'>
                        {isRegister ? 'Possiedi già un account? Effettua il Login!' : 'Non hai un account? Registrati adesso!'}
                    </Typography>
                        <Button size='large' variant='text' onClick={()=>handleToggle('login')}>
                          {isRegister ? 'Login' : 'Registrati'}
                        </Button>
                </Grid>
                {!isRegister && viewReset && 
                    <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start', gap:1}}>
                        <Typography variant='subtitle1'>
                            {'Hai dimenticato la password?'}
                        </Typography>
                            <Button size='large' variant='text' onClick={() => handleToggle('reset')}>
                              {'Reimposta la password'}
                            </Button>
                    </Grid>
                    }  
            </Grid>
        </DialogActions>
        </form>
    </Dialog>
  );
};

export default Login;