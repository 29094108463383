import React, {useRef, useState, useEffect} from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTheme } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';
import {
  Typography,
  Box,
  Grid,
} from '@mui/material';
import { RegalaButton } from 'components/Buttons/RegalaButton';

export const SectionView = ({section, index}) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Set state based on whether the component is intersecting
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // null means it observes changes in the viewport
        rootMargin: '0px',
        threshold: 0.4, // Trigger when 10% of the element is in view
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  // Style object for fade-in effect
  const textFadeInStyle = useSpring({
    opacity: showText ? 1 : 0,
    transform: showText ? 'translateY(0)' : 'translateY(20px)',
    delay: 100, // Optional delay to ensure image animation is noticed
  });
    // Determine animation direction based on index
const fromDirection = index % 2 === 0 ? '-100%' : '100%';

    // Animation for the image
   
const imageAnimation = useSpring({
    from: { transform: `translate3d(${fromDirection}, 0, 0)`, opacity: 0 },
    to: {
      transform: isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)',
      opacity: isVisible ? 1 : 0,
    },
    config: { mass: 1, tension: 210, friction: 18 },
    onRest: () => setShowText(isVisible), // Callback when animation is done
  });

  return (
            <Box  ref={ref} sx={{ minHeight:'100vh', display:'flex', justifyContent:'center', alignItems:'center',p:2}}>
                <Grid container spacing={4} sx={{flexDirection: index % 2 === 0 ? 'row' : 'row-reverse'}} >
                    <Grid item  xs={12} sm={12} md={12} lg={6} sx={{display:'flex', alignItems:'center',justifyContent:{xs:'center',sm:'center',md:'center',lg:index % 2 === 0 ? 'flex-end' : 'flex-start'}}}>
                        
                        <animated.div style={imageAnimation}>
                            <LazyLoadImage  
                                            srcSet={section.imageSrcSet}
                                            sizes="(max-width: 600px) 100vw, (max-width: 1200px) 70vw, 40vw"
                                            src={section.image}
                                            effect="blur"
                                            placeholderSrc={section.placeholder}
                                            style={{width:'100%'}}

                            />
                        </animated.div>
                    </Grid>
                  
                    <Grid item  xs={12} sm={12} md={12} lg={6} sx={{display:'flex', alignItems:'center', justifyContent:{xs:'center',sm:'center',md:'center',lg:index % 2 === 0 ? 'flex-start' : 'flex-end'}}}>
                        <animated.div style={textFadeInStyle}>
                            <Grid container spacing={4} sx={{maxWidth:'600px',}}>
                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center',p:2}}>
                                    <Typography variant='h2' sx={{color: theme.palette.grey.main  ,fontFamily: '"Playfair Display", serif',
                                        fontSize:'clamp(4rem, 6vw, 5rem)',
                                        fontWeight: 'normal',
                                        textTransform: 'none',}}>
                                        {section.title}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <Typography variant='subtitle1' sx={{color: theme.palette.grey.dark ,
                                        fontSize:'clamp(1.2rem, 6vw, 1.5rem)',
                                        fontWeight: 'normal',
                                        textTransform: 'none',}}>
                                        {section.body}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <RegalaButton text={section.text_button} url={section.url}/>
                                </Grid>
                            </Grid>
                        </animated.div>
                    </Grid>
                   
                </Grid>
            </Box>
        );
}
