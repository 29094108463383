import { Dashboard, Logout, Settings } from '@mui/icons-material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { logout } from 'features/auth/AuthActions';
import { useValue } from 'context/ContextProvider';
import { isAdmin, isEmployee, isManager, isOwner } from 'services/getUserRole';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EuroIcon from '@mui/icons-material/Euro';
import ReceiptIcon from '@mui/icons-material/Receipt';
import {
  Divider,
} from '@mui/material';
import useCheckToken from 'features/auth/useCheckToken';
const UserMenu = ({ anchorUserMenu, setAnchorUserMenu }) => {
  useCheckToken()
  const {
    dispatch,
    state: {
      currentUser,
    },
  } = useValue();
  const handleCloseUserMenu = () => {
    setAnchorUserMenu(null);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    logout(dispatch);
    navigate(location?.state?.previousUrl
      ? location.state.previousUrl
      : '/');
  };
  const showDashboard = (isAdmin(currentUser) || isOwner(currentUser) || isManager(currentUser) || isEmployee(currentUser))

  return (
    <>
       <Menu
        disableScrollLock={true}
         anchorEl={anchorUserMenu}
         open={Boolean(anchorUserMenu)}
         onClose={handleCloseUserMenu}
         onClick={handleCloseUserMenu}
          d="account-menu"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        
        <MenuItem onClick={() => navigate((isAdmin(currentUser) || isEmployee(currentUser) || isManager(currentUser)) ? '/dashboard/support' : '/my-tickets')}>
          <ListItemIcon>
            <ConfirmationNumberIcon fontSize="small" />
          </ListItemIcon>
         {isAdmin(currentUser) ? 'Supporto' : 'User Panel'}
        </MenuItem>
  
        <MenuItem onClick={() => navigate((isAdmin(currentUser) || isEmployee(currentUser) || isManager(currentUser)) ? '/dashboard/invoices' : '/my-invoices')}>
          <ListItemIcon>
            <ReceiptIcon fontSize="small" />
          </ListItemIcon>
          Fatture
        </MenuItem>
        {showDashboard && (
        <MenuItem onClick={() => navigate('/dashboard')}>
          <ListItemIcon>
            <Dashboard fontSize="small" />
          </ListItemIcon>
          Dashboard
        </MenuItem>
        )}
        <Divider />
        {currentUser && (
        <MenuItem onClick={() => navigate('/profile')}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;