
import posthog from "posthog-js";
export const logout = (dispatch) => {
  posthog.reset();

  // Dispatch RESET actions based on the provided global context
  dispatch({ type: 'RESET_NEW_INVOICE' }); // Reset new invoice
  dispatch({ type: 'RESET_DISCOUNT' }); // Reset discount
  dispatch({ type: 'RESET_USER_PROFILE' }); // Reset user profile
  dispatch({ type: 'RESET_CONTACT_FORM' }); // Reset contact form
  dispatch({ type: 'RESET_CASH_UP' }); // Reset cash up
  dispatch({ type: 'RESET_CASH_UP_ERROR' }); // Reset cash up error
  dispatch({ type: 'RESET_RESTAURANT_DETAILS' }); // Reset restaurant details
  dispatch({ type: 'RESET_USER_ERROR' }); // Reset user error
  dispatch({ type: 'RESET_DISCOUNT_ERROR' }); // Reset discount error
  dispatch({ type: 'END_LOADING' }); // End loading
  dispatch({ type: 'UPDATE_USER', payload: null }); // Update user to null
  dispatch({ type: 'UPDATE_CURRENT_USER', payload: null }); // Update current user to null

  // Remove local storage items
  localStorage.removeItem("currentUser");
};

