
import axios from 'axios'
import {AuthHeader} from 'services/AuthHeader';

export const NotificationsApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})


export const getNotificationsByUserId = async(id)=> {
    NotificationsApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await NotificationsApi.get(`/notifications/${id}`, id)
    return response.data
}

export const updateNotificationState = async (notification) => {
    NotificationsApi.defaults.headers['Authorization'] = AuthHeader();
    return await NotificationsApi.put(`/notifications/${notification.id}`,notification)
}

export const deleteNotification = async ({ id }) => {
    NotificationsApi.defaults.headers['Authorization'] = AuthHeader();
    return await NotificationsApi.delete(`/notifications/${id}`, id)
}

export const deleteAllNotification = async ({ id }) => {
    NotificationsApi.defaults.headers['Authorization'] = AuthHeader();
    return await NotificationsApi.delete(`/notifications/all/${id}`, id)
}

export const markAllReadNotification = async (id) => {
    NotificationsApi.defaults.headers['Authorization'] = AuthHeader();
    return await NotificationsApi.put(`/notifications/${id}`,id)
}


