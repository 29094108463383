import React, { useRef, useState, useEffect } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const SectionText = ({ section }) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // Observes changes in the viewport
        rootMargin: '0px',
        threshold: 0.1, // Trigger when 10% of the element is in view
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  // Style object for fade-in effect
  // Directly use isVisible to control the animation
  const textFadeInStyle = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(20px)',
    delay: 100, 
    // Adds a delay to the animation to ensure it's noticeable
    onRest: () => setShowImage(isVisible),
  });

  const imgFade = useSpring({
    opacity: showImage ? 1 : 0,
    transform: showImage ? 'translateY(0)' : 'translateY(20px)',
    delay: 100,
  })

  return (
    <Box ref={ref} sx={{ minHeight: '50vh', alignItems: 'center', justifyContent:'center', display: 'flex'}}>
        <Box>
          <animated.div style={textFadeInStyle}>
              <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                  <Typography
                    variant='h2'
                    textAlign={'center'}
                    sx={{
                      color: theme.palette.secondary.main,
                      fontFamily: '"Playfair Display", serif',
                      fontSize: 'clamp(2rem, 6vw, 4rem)',
                      fontWeight: 'normal',
                      fontStyle: 'italic',
                      textTransform: 'none',
                      maxWidth: 1200,
                      flexWrap:'wrap'
                    }}
                  >
                      {section.text}
                  </Typography>
              </Box>
          </animated.div>
          {section.image &&
              <animated.div style={imgFade} >
                  <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                      {section.image}
                  </Box>
              </animated.div>
          }
      </Box>
    </Box>
  );
};
