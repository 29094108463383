import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useTheme } from '@mui/material/styles';
import { Heading } from 'components/Heading';

import {
  Box,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useValue } from 'context/ContextProvider';
export const Others = () => {
    const theme = useTheme();
    const {
      state: { loading, currentRestaurant },
      dispatch,
    } = useValue();
    console.log(currentRestaurant.public_key)
    const link_settings = [
      {
        restaurant_public_key: 'FBD60CC0f298229fc455ccaA2D3a33',
        restaurant_link: 'https://www.lofficinasirolo.com/',
        link_name: 'Ristorante Lofficina'
      },
      {
        restaurant_public_key: '7e4f39DABBdFc9A05cf6b92B877bdb',
        restaurant_link: 'https://www.losteriasirolo.com/',
        link_name: 'Ristorante Losteria'
      }
    
    ]
//Import restaurants from database in the future
    

  return (
  <Grid container spacing={2}>
      <Grid item xs={12} display={{xs:'none', sm:'block'}} >
          <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                  <Heading text={'Altro'} color={theme.palette.primary.main} size={'small'}/>
          </Box>
      </Grid>
      <Grid item xs={12}sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <Box>
            {link_settings && link_settings.map((link, index) => ( 
            link.restaurant_public_key !== currentRestaurant?.public_key && <>
                <Typography variant='subtitle2' sx={{color:theme.palette.grey.main}}>Siamo anche qui</Typography>
                <Link href={link.restaurant_link} underline="none" variant='subtitle2' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
                        {link.link_name}
                </Link>
                </>
            ))}
            <Typography variant='subtitle2'  sx={{color:theme.palette.grey.main}} >Partners</Typography>
            <Link href={'https://www.ndrspedizioni.com/'} underline="none" variant='subtitle2' color={'inherit'} sx={{':hover':{ color:theme.palette.secondary.main} }}>
                    {'NDR Spedizioni'}
            </Link>
          </Box>
      </Grid>
  </Grid>
  )
}