import { useState, useEffect } from 'react';

function useResponsiveImageSize() {
  const [selectedSize, setSelectedSize] = useState('lg'); // Default to 'lg'

 useEffect(() => {
    const updateSize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const pixelDensity = window.devicePixelRatio;

      // Updated criteria to include screen height
      const criteria = [
        { maxWidth: 480, maxHeight: 800, maxDensity: 3, size: 'xs' },
        { maxWidth: 900, maxHeight: 1366, maxDensity: 2, size: 'sm' },
        { maxWidth: 1200, maxHeight: Infinity, maxDensity: 2.5, size: 'md' },
        { maxWidth: Infinity, maxHeight: Infinity, maxDensity: Infinity, size: 'lg' },
      ];

      // Find the matching size based on both width, height, and pixel density
      const selected = criteria.find(criterion =>
        screenWidth <= criterion.maxWidth &&
        screenHeight <= criterion.maxHeight &&
        pixelDensity <= criterion.maxDensity
      )?.size;

      setSelectedSize(selected || 'lg');
    };
    // Initial update
    updateSize();

    // Add event listener for window resize
    window.addEventListener('resize', updateSize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', updateSize);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return selectedSize;
}

export default useResponsiveImageSize;
