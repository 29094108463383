import {
    Grid,
    Box,
    Button
  } from '@mui/material';
    import { useState, useEffect, useRef} from 'react';
    import { AddressInfo } from 'features/footer/components/AddressInfo';
    import { FooterContacts } from 'features/footer/components/FooterContacts';
    import { Social } from 'features/footer/components/Social';
    import { Others} from 'features/footer/components/Others';
    import { useTheme } from '@mui/material/styles';
    import { useValue } from 'context/ContextProvider';
    import { PrivacyLinks } from 'features/footer/components/PrivacyLinks';
    import LoginIcon from '@mui/icons-material/Login';
    import { LazyLogo } from 'components/LazyLogo';
    import useWindowDimensions from 'services/getWindowDimensions';

export const Footer = () => {
const theme = useTheme();
const { width, height } = useWindowDimensions();
const {
    state: { currentUser , currentRestaurant},
    dispatch,
  } = useValue();
  return (
<Box sx={{
    minHeight: '50vh',
    backgroundColor: theme.palette.backGround.dark,
    display: 'flex', // Enable flexbox
    flexDirection: 'column', // Stack children vertically
    justifyContent: 'space-between', // Space between items
    height: '100%' // Ensure it takes full height of its content or minHeight
    }}>
        <Grid container spacing={2} sx={{ pt: 10 }}>
             <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: { xs: 'center', sm: 'center' } }}>
                <AddressInfo />
            </Grid> 
            <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: { xs: 'center', sm: 'center' } }}>
                <FooterContacts />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: { xs: 'center', sm: 'center' } }}>
                <Social />
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: { xs: 'center', sm: 'center' } }}>
                <Others />
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 'auto', pb: 2 }}>
            <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: { xs: 'center', sm: 'flex-start' }, flexGrow: 1 }}>
                <PrivacyLinks />
            </Grid>
            <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {currentRestaurant?.alt_image_url &&
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,maxHeight:{ xs: 50, sm: 100 }, maxWidth:{ xs: 50, sm: 100 } }}>
                    <LazyLogo logo_url={currentRestaurant.alt_image_url}  />
                </Box>
                }
            </Grid>
            <Grid item xs={12} sm={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-end' }, pr: { xs: 0, sm: 10 } }}>
                {!currentUser &&
                <Button variant={'text'} size='large'  sx={{p:2,borderRadius:5,'&:hover': {backgroundColor: 'transparent', color: 'secondary.main',},}} 
                    fullWidth onClick={()=>dispatch({type:'OPEN_LOGIN'})} color={'primary'} startIcon={<LoginIcon fontSize='large'/>}>
                        Accedi
                </Button>
                }
            </Grid>
        </Grid>
    </Box>
  )
}
