import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useTheme } from '@mui/material/styles';
import { Heading } from 'components/Heading';
import { useValue } from 'context/ContextProvider';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
export const FooterContacts = () => {
    const theme = useTheme();
    const {
      state: {
        currentRestaurant
      },
      dispatch,
    } = useValue();
    

  return (
    <Grid container spacing={2}>
        <Grid item xs={12} display={{xs:'none', sm:'block'}} >
            <Box sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <Heading text={'Contatti'} color={theme.palette.primary.main} size={'small'}/>
            </Box>
        </Grid>
        <Grid item xs={12}sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            {currentRestaurant &&
            <Box>
              <Typography variant='subtitle2' component='a' href={`tel:${currentRestaurant.phone_number} `} sx={{textDecoration:'none', color:theme.palette.secondary.main}}>TEL: <strong>{currentRestaurant.phone_number}</strong></Typography>
              <Typography variant='subtitle2'>{currentRestaurant.email && currentRestaurant.email}</Typography>
            </Box>
            }
        </Grid>
    </Grid>
  )
}
