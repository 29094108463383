import React, {useEffect, useState, useMemo, useRef} from 'react'
import { useTheme } from '@mui/material/styles';
import { Heading } from 'components/Heading';
import { useValue } from 'context/ContextProvider';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';
export const AddressInfo = () => {
    const theme = useTheme();
    const {
      state: {
        currentRestaurant
      },
      dispatch,
    } = useValue();
    
   
  return (<>
    {currentRestaurant && currentRestaurant.address && 
    <Grid container spacing={2}>
        <Grid item xs={12}sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Heading text={currentRestaurant.name} color={theme.palette.primary.main} size={'small'}/>
        </Grid>
        <Grid item xs={12}sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <Box>
                <Typography variant='subtitle2'>{currentRestaurant.address.address + ', ' + currentRestaurant.address.cap}</Typography>
                <Typography variant='subtitle2' paragraph={true}>{currentRestaurant.address.city + ' (' + currentRestaurant.address.province + ')' }</Typography>
            </Box>
        </Grid>
    </Grid>
    
    }
    </>
  )
}
