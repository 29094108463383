import React, {useRef, useState, useEffect} from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTheme } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';
import useWindowDimensions from 'services/getWindowDimensions';
import {
  Typography,
  Box,
  Grid,
} from '@mui/material';
import { RegalaButton } from 'components/Buttons/RegalaButton';


export const SectionHeroText = ({section, index}) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showText, setShowText] = useState(false);
  const [maxDimension, setMaxDimension] = useState(0)
  const [marginTop, setMarginTop] = useState(0)
  const [marginLeft, setMarginLeft] = useState(0)
  const {width, height} = useWindowDimensions()

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Set state based on whether the component is intersecting
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // null means it observes changes in the viewport
        rootMargin: '0px',
        threshold: 0.4, // Trigger when 10% of the element is in view
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  useEffect (()=>{
    if (width > height) {
      setMaxDimension(height/2)
    } else {
      setMaxDimension(width/2)
    }
    height > 600 ? setMarginTop(-100) : setMarginTop(0)
    height > 900 ? setMarginTop(-150) : setMarginTop(0)
    height > 1100 ? setMarginTop(-200) : setMarginTop(0)


    //get circle width
    const circleWidth = document.getElementById('circle').clientWidth;
    const margin_left = (width-circleWidth)/2
    setMarginLeft(margin_left)
  },[width, height])

  // Style object for fade-in effect
  const textFadeInStyle = useSpring({
    opacity: showText ? 1 : 0,
    transform: showText ? 'translateY(0)' : 'translateY(20px)',
    delay: 100, // Optional delay to ensure image animation is noticed
    position: 'absolute', // Position this item absolutely within its relative parent
    top: 0, // Adjust this value as needed to move the text up, slightly out of the image
    left: 100, // Center the text horizontally
    transform: 'translateX(-50%)', // Ensure it's centered regardless of its width
  });
    // Determine animation direction based on index
const fromDirection = index % 2 === 0 ? '-100%' : '100%';

    // Animation for the image
   
const imageAnimation = useSpring({

    from: { transform: `translate3d(${fromDirection}, 0, 0)`, opacity: 0 },
    to: {
      transform: isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)',
      opacity: isVisible ? 1 : 0,
    },
    config: { mass: 1, tension: 210, friction: 18 },
    onRest: () => setShowText(isVisible), // Callback when animation is done
  });
  return (
          
                          <Box ref={ref} id={'circle'} sx={{display:'flex', alignItems:'center', justifyContent:'center', width:maxDimension, height:maxDimension, minHeight:300, minWidth:300, borderRadius:'50%', position:'relative', marginLeft:marginLeft,
                          backgroundImage: {
                            xs: `url(${section.background_image_src.xs})`,
                            sm: `url(${section.background_image_src.sm})`,
                            md: `url(${section.background_image_src.md})`,
                            lg: `url(${section.background_image_src.lg})`,
                          },
                          // Use theme breakpoints for media queries
                            '@media (min-width: 0px)': {
                              backgroundImage: `url(${section.background_image_src.xs})`,
                            },
                            '@media (min-width: 600px)': {
                              backgroundImage: `url(${section.background_image_src.sm})`,
                            },
                            '@media (min-width: 900px)': {
                              backgroundImage: `url(${section.background_image_src.md})`,
                            },
                            '@media (min-width: 1200px)': {
                              backgroundImage: `url(${section.background_image_src.lg})`,
                            },//{xs: `url(${BackgroundXs})`, sm:`url(${BackgroundMd})`, md:`url(${BackgroundMd})`,lg:`url(${BackgroundLg})`}, 
                              backgroundSize: 'cover',  
                              backgroundRepeat: 'no-repeat', 
                              backgroundPosition: 'center',  
                              boxShadow: {xs:'none', sm:'inset 0 -20px 20px -15px rgba(0, 0, 0, 0.5)'}
                              }}> 
                             
                              <animated.div style={imageAnimation}>
                                  <animated.div style={textFadeInStyle}>
                                      <Grid container spacing={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', width:'100vw'}} >
                                          <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center',p:2}}>
                                              <Typography variant='h2' sx={{color: theme.palette.grey.main  ,fontFamily: '"Playfair Display", serif',
                                                  fontSize:'clamp(4rem, 20vw, 12rem)',
                                                  fontWeight: 'normal',
                                                  textTransform: 'none',}}>
                                                  {section.title}
                                              </Typography>
                                          </Grid>
                                          {section.body &&
                                          <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center',p:2, }}>
                                              <Typography variant='subtitle1' sx={{color: theme.palette.backGround.light ,
                                                  fontSize:'clamp(1.2rem, 60vw, 1.5rem)',
                                                  fontWeight: 'normal',
                                                  textTransform: 'none',
                                                  maxWidth:'600px'}}>
                                                  {section.body}
                                              </Typography>
                                          </Grid>
                                          }
                                           {section.url &&
                                          <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                              <RegalaButton text={section.text_button} url={section.url}/>
                                          </Grid>
                                           }
                                      </Grid>
                                  </animated.div>
                              </animated.div>
                             
                          </Box>
                          
                  
        );
}
