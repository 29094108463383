import axios from 'axios'
import {AuthHeader} from 'services/AuthHeader';

export const restaurantApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})


export const getAllRestaurants = async (orderByColumn, orderByType) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await restaurantApi.get("/restaurant/",  {params: {orderByColumn:orderByColumn, orderByType:orderByType}})
    return response.data
}


export const getAllRestaurantsByBusinessId = async (id) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await restaurantApi.get(`/restaurant/business/${id}`, id)
    return response.data
}


export const getRestaurantStats = async(id, startDate, endDate, show_vat, show_hidden)=> { 
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await restaurantApi.get(`/restaurant/stats/${id}`,{params: { startDate: startDate, endDate:endDate, show_vat:show_vat, show_hidden:show_hidden}})
    return response.data
}

export const getAllRestaurantsPublic = async () => {
    const response = await restaurantApi.get("/restaurant/public")
    return response.data
}

export const getRestaurantById = async (id) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await restaurantApi.get(`/restaurant/${id}`, id)
    return response.data
}

export const getRestaurantByHostname = async (hostname) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await restaurantApi.get(`/restaurant/hostname/${hostname}`, hostname)
    return response.data
}


export const getCurrentVersion = async (hostname, current_version) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await restaurantApi.get(`/restaurant/current_version/${hostname}/${current_version}`, hostname, current_version)
    return response.data
}



export const createRestaurant = async (restaurant) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    return await restaurantApi.post("/restaurant/",restaurant)
}

export const addRestaurantHostname = async (restaurant) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    return await restaurantApi.post("/restaurant/hostname/",restaurant)
}

export const deleteRestaurantHostname = async (restaurant) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    return await restaurantApi.post("/restaurant/hostname/delete/",restaurant)
}

export const getRestaurantsNames = async(business_id)=> { 
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    const response = await restaurantApi.get(`/restaurant/names/`,{params :{business_id:business_id}})
    return response.data
}

export const updateRestaurant = async (restaurant) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    return await restaurantApi.put(`/restaurant/${restaurant.id}`,restaurant)
}

export const deleteRestaurant = async ({ id }) => {
    restaurantApi.defaults.headers['Authorization'] = AuthHeader();
    return await restaurantApi.delete(`/restaurant/${id}`, id)
}

