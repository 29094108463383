import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useValue } from 'context/ContextProvider'

const PasswordField = ({passwordRef, id, name, label, error, helperText, onChange}) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    state: { userError},
    dispatch,
  } = useValue();

  const handleClick = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  return (
    <TextField
      margin="normal"
      variant="outlined"
      id={id}
      label={label}
      name = {name}
      type={showPassword ? 'text' : 'password'}
      fullWidth
      inputRef={passwordRef}
      required
      helperText={helperText}
      error = {error}
      onChange={onChange}
      InputProps={{
        sx: { borderRadius: 5 },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="view password" onClick={handleClick} onMouseDown={handleMouseDown}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;