import {
  Grid,
  Box,
  Container,
  Typography,

} from '@mui/material';
import { useState, useEffect, useRef, lazy} from 'react';
import { useValue } from 'context/ContextProvider';
import { useTheme } from '@mui/material/styles';
import useWindowDimensions from 'services/getWindowDimensions';
import {Helmet} from "react-helmet";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useGenerateSectionList } from 'features/section/SectionsList';
import { SectionGenerator } from 'components/SectionGenerator';

const NewsAlert = lazy(() => import('components/NewsAlert').then(module => ({ default: module.NewsAlert })));
export const Default = () => {
  
  //background image url

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [navBarHeight, setNavBarHeight] = useState(0)
  const theme = useTheme();
  const { width, height } = useWindowDimensions();
  const SectionList = useGenerateSectionList();
const {
  state: { currentUser, currentRestaurant  },
  dispatch,
} = useValue();

useEffect(() => {
  const navBar = document.getElementById('navbar-main');
  if (navBar) {
      setNavBarHeight(navBar.clientHeight); 
  }
  }, []);
  // THIS WILL ALL BE CHANGED, META DATA WILL BE STORED IN THE DB.
  useEffect(() => {
    const restaurant = SectionList.find(element => element.restaurant_public_key === currentRestaurant.public_key);
    if (restaurant) {
      setTitle(restaurant.title || 'Default Title');
      setDescription(restaurant.description || 'Default Description');
    }
  }, [currentRestaurant]);


return(<>
<Helmet>
    <link rel="canonical" href={window.location.href} />
    <title>{title}</title>
    <meta name='description' content={description}/>
     {/* Open Graph Tags */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={currentRestaurant && `${window.location.href}/logo/${currentRestaurant.public_key}/${currentRestaurant.image_url}`} />
    <meta property="og:image:alt" content="NDR Logo" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={window.location.href} />
    {/* Twitter Card Tags */}
    <meta name="twitter:card" content={currentRestaurant && `${window.location.href}/logo/${currentRestaurant.public_key}/${currentRestaurant.image_url}`}/>
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={currentRestaurant && `${window.location.href}/logo/${currentRestaurant.public_key}/${currentRestaurant.image_url}`} /> 
    

</Helmet>            
  <NewsAlert />
  {currentRestaurant ?
  <SectionGenerator sectionList={SectionList} />
  :
  <Box sx={{height:'100vh', display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Typography variant='h5'>
          Nessun ristorante aggiunto
      </Typography>
  </Box>}
  </>
)
}