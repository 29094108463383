import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';// Adjust the path as needed

export const ActionButton = ({ mainProps, optionalProps = {}, icon,url, text }) => {
  return (
    <Button 
    startIcon={icon? icon:null}
    {...optionalProps}
    {...mainProps}
    variant="contained"
    sx={{
        minWidth:100,
      p: 2,
      borderRadius: 30,
      boxShadow: 'none',
      maxWidth: 150,
      transition: 'transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)'
      }
    }}
    component={Link}
    size="large"
    fullWidth
    to={url}
    color="primary"
  >
    {text}
  </Button>
  );
};