import React, {useRef, useState, useEffect, memo} from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useTheme } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';
import { ReactComponent as Arrow } from 'assets/images/arrow-down.svg';
import useResponsiveImageSize from 'services/useResponsiveBackgroundImages';
import {
  Typography,
  Box,
  Grid,
} from '@mui/material';
import { RegalaButton } from 'components/Buttons/RegalaButton';


export const SectionHero = ({section, index}) => {
  const theme = useTheme();
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Set state based on whether the component is intersecting
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null, // null means it observes changes in the viewport
        rootMargin: '0px',
        threshold: 0.4, // Trigger when 10% of the element is in view
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  // Style object for fade-in effect
  const textFadeInStyle = useSpring({
    opacity: showText ? 1 : 0,
    transform: showText ? 'translateY(0)' : 'translateY(20px)',
    delay: 100, // Optional delay to ensure image animation is noticed
    onRest: () => setShowArrow(showText),
  });
    // Determine animation direction based on index
const fromDirection = index % 2 === 0 ? '-100%' : '100%';

    // Animation for the image
   
const imageAnimation = useSpring({

    from: { transform: `translate3d(${fromDirection}, 0, 0)`, opacity: 0 },
    to: {
      transform: isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)',
      opacity: isVisible ? 1 : 0,
    },
    config: { mass: 1, tension: 210, friction: 18 },
    onRest: () => setShowText(isVisible), // Callback when animation is done
  });

  const showArrowAnimation = useSpring({
    opacity: showArrow ? 1 : 0,
    delay:100,
    maxWidth:120,
    animation: 'pulse 2s infinite', 
  })

  const imageSize = useResponsiveImageSize()
  return (
          
    <Box ref={ref} sx={{
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      backgroundImage: section.title ? `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${section.background_image_src[imageSize]})` : `url(${section.background_image_src[imageSize]})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      minHeight: '100vh',
      boxShadow: {xs: 'none', sm: 'inset 0 -20px 20px -15px rgba(0, 0, 0, 0.5)'}
    }}>
                             
                              <animated.div style={imageAnimation}>
                                  <animated.div style={textFadeInStyle}>
                                      <Grid container spacing={4} sx={{ display:'flex', alignItems:'stretch', justifyContent:'center'}}>
                                          <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center',p:2}}>
                                              <Typography variant='h2' sx={{color: theme.palette.backGround.light  ,fontFamily: '"Playfair Display", serif',
                                                  fontSize:'clamp(4rem, 10vw, 12rem)',
                                                  fontWeight: 'normal',
                                                  textTransform: 'none',}}>
                                                  {section.title}
                                              </Typography>
                                          </Grid>
                                          {section.body &&
                                          <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center',p:2}}>
                                              <Typography variant='subtitle1' sx={{color: theme.palette.backGround.light ,
                                                  fontSize:'clamp(1.2rem, 6vw, 1.5rem)',
                                                  fontWeight: 'normal',
                                                  textTransform: 'none',
                                                  maxWidth:'600px'}}>
                                                  {section.body}
                                              </Typography>
                                          </Grid>
                                          }
                                           {section.url &&
                                          <Grid item xs={12} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                              <RegalaButton text={section.text_button} url={section.url}/>
                                          </Grid>
                                           }
                                           <Grid item xs={12} sx={{display:'flex', alignItems:'flex-end', justifyContent:'center'}}>
                                              <animated.div style={showArrowAnimation}>
                                                  <Arrow style={{ maxWidth: 'clamp(4rem, 6vw, 12rem)', width: '100%', height: 'auto' }} />
                                              </animated.div>
                                           </Grid>  
                                      </Grid>
                                  </animated.div>
                              </animated.div>
                             
                          </Box>
                              
                  
        );
}


              