import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Grid,
  Container,
  IconButton,
  Toolbar,
  Paper,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useValue } from 'context/ContextProvider';
import UserIcons from 'components/UserIcons';
import { Link, useNavigate } from 'react-router-dom';
import Logo from 'assets/logo/lofficina_white.svg';
import useWindowDimensions from 'services/getWindowDimensions';
import { Helmet } from 'react-helmet';
import { BookButton } from './Buttons/BookButton';
import { RegalaButton } from './Buttons/RegalaButton';
import { MenuButton } from './Buttons/MenuButton';
import { isAdmin, isEmployee, isManager } from 'services/getUserRole';
import { useSpring, animated } from '@react-spring/web';
import { LazyLogo } from './LazyLogo';

export const NavbarDialog = () => {

  const [navBarHeight, setNavBarHeight] = useState(0)
  const { width, height } = useWindowDimensions();
  const theme = useTheme();
  const navigate = useNavigate()
 
  const {
      state: { currentUser, colors, openMenu, currentRestaurant },
      dispatch, 
  } = useValue();

  // Define the spring-animated style for the logo
  const [logoStyle, logoApi] = useSpring(() => ({
    maxWidth: '80px',
    opacity:1, // Initial maxWidth
    from: { maxWidth: '80px' ,opacity:1},
  }));

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = (window.scrollY / window.innerHeight) * 100;
      if (scrollPercentage > 10) {
        // Update logoStyle to change maxWidth to 50 when scrolled more than 10%
        logoApi.start({ maxWidth: '30px',opacity: 0});
        setNavBarHeight(80) // Update state to reflect small logo size
      } else {
        // Revert logoStyle maxWidth to 80 when scrolled back within 10%
        logoApi.start({ maxWidth: '80px' ,opacity:1 });
        setNavBarHeight(120)// Update state to reflect big logo size
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [logoApi]);

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
      if (openMenu.open) {
          setIsVisible(true);
      } else {
          const timer = setTimeout(() => setIsVisible(false), 500); // match this with your transition duration
          return () => clearTimeout(timer);
      }
  }, [openMenu.open]);
    
  const toggleMenu = () => {
      if (!openMenu.open) {
          dispatch({type:'OPEN_MENU', payload:{open:true, scroll:false, animate:true}})
      } else {
          dispatch({type:'OPEN_MENU', payload:{open:false, scroll:true, animate:false}})
      }
  };

  useEffect(()=>{
      if (openMenu.scroll) {
          document.body.style.overflow = 'auto'; // Enable scrolling
      } else {
          document.body.style.overflow = 'hidden'; // Disable scrolling
      }
  },[openMenu.scroll])

  const visibilityStyle = openMenu.open ? {
      visibility: 'visible',
      opacity: 1,
      transform: 'translateY(0)',
      transitionProperty: 'opacity, transform, visibility',
      transitionDuration: '500ms, 500ms, 0ms',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), linear',
      transitionDelay: '0s, 0s, 0s',
    } : {
      visibility: isVisible ? 'visible':'hidden',
      opacity: 0,
      transform: 'translateY(-100%)',
      transitionProperty: 'opacity, transform, visibility',
      transitionDuration: '500ms, 500ms, 0ms',
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), linear',
      transitionDelay: '0s, 0s, 500ms', // Adjust delay here
    };

  const menuItems = [
    { title: 'Home', url: '/' },
    { title: 'Cucina', url: '/cucina', scrollIntoView: false },
    { title: 'Locale', url: '/locale', scrollIntoView: false },
    { title: 'Noi', url: '/noi'  , scrollIntoView: true},
    { title: 'Contatti', url: '/contacts' , scrollIntoView: false },
    { title: 'Prenota', url: '/prenota' , scrollIntoView: false },
    { title: 'Regala', url: '/regala' , scrollIntoView: false },
  ];

  const showDashboard = isAdmin(currentUser) || isManager(currentUser) || isEmployee(currentUser)

  return (
    <>
       <Helmet>
            <link rel="preload" href="assets/icons/ndr-logo.ico" as="image" />
        </Helmet>
        <AppBar id={'navbar-main'} elevation={0} 
                style={{backgroundColor: openMenu.animate ? theme.palette.backGround.main:'transparent', 
                left:'50%', transform: 'translate(-50%)', 
                minHeight:60,
                boxShadow: 'none', width:'100vw' , border:0,
                position:'fixed',
                color: colors.isLight ? theme.palette.grey.main: theme.palette.primary.dark,
                transition:'backgroundColor 700ms cubic-bezier(0.4, 0, 0.2, 1)'
             }} 
              position="fixed" 
              >
           
                <Toolbar disableGutters sx={{pr:openMenu.open ? '0.95em' : 0}} >
                    <Grid container spacing={2} padding={2}>
                        <Grid item xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'flex-start',pl:2}}>
                            <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={toggleMenu}
                            color="inherit"
                            >
                                {openMenu.animate ? <CloseIcon fontSize='large'/> : <MenuIcon fontSize='large'/>}
                            </IconButton>
                        </Grid>

                        <Grid item xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                            {currentRestaurant?.image_url && 
                              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <animated.div style={logoStyle}>
                                      <LazyLogo logo_url={currentRestaurant.image_url} public_key={currentRestaurant.public_key} />
                                  </animated.div>
                              </Box>
                              }
                        </Grid>

                        <Grid item xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                           <Box sx={{display:'flex', justifyContent:'flex-end', alignItems:'center', gap:1,pr:2}}>
                                {currentUser &&
                                  <UserIcons />
                                }
                                {width > 900 &&
                                <>
                                  <BookButton text={'Prenota'} url={'/prenota'}/>
                                  <RegalaButton text={'Regala'} url={'/regala'}/>
                                </>
                                }
                                </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
      
        </AppBar>
                 
        <Paper elevation={0} style={{
        ...visibilityStyle,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        marginTop: `${navBarHeight -2}px`,
        zIndex: 1,
        height: `calc(100vh - ${ navBarHeight }px)`,
        overflowY: 'auto',
        backgroundColor: theme.palette.backGround.main,
        }}>
                <Box sx={{display: 'flex', alignItems: 'center',justifyContent: 'center',minHeight:  `calc(100vh - ${navBarHeight}px)`,pb:5}}>
                <Container>
                <Grid container spacing={2}>
                    {menuItems.map((item, index) => (
                      <Grid item xs={12} sm={12} md={12} lg={6} key={item +'-' + index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Box
                            sx={{
                              opacity: openMenu.open ? 1 : 0,
                              transform:openMenu.open ? 'translateY(0)' : 'translateY(20px)',
                              transition: `opacity 500ms ${index * 150}ms, transform 500ms ${index * 150}ms`, // Staggered delay
                            }}
                          >
                            <MenuButton text={item.title} url={item.url} sectionName={item.title.toLowerCase()} scrollIntoView={item.scrollIntoView}/>
                          </Box>
                      </Grid>
                    ))}
                  </Grid>
                    </Container>
                </Box>
            </Paper>
       
    </>
  );
}

{/* <Dialog
fullScreen
open={openMenu.open}
onClose={handleClose}
TransitionComponent={Transition}
hideBackdrop
disableEnforceFocus={true}

sx={{
  '& .MuiDialog-container': {
    alignItems: 'flex-start', // Aligns the dialog below the AppBar
  },
  '& .MuiBackdrop-root': {
    pointerEvents: 'none', // Allows click and hover events through the backdrop.
  },
  '& .MuiPaper-root': {
    marginTop: `${navBarHeight +55}px`, // Adjusts the top margin to the height of the AppBar
    maxHeight: `calc(100vh - ${navBarHeight}px)`, // Adjusts the dialog height to consider the AppBar
    borderRadius: 0, // Optional: removes the border radius for a full-width effect
  },
}} */}